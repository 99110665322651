import React, {Component} from "react";
import {observer, inject} from "mobx-react";

import {parseCountToTime} from "../../../utils/editorKeyEvents";
import {hotKeys} from "../../../utils/hotkey";

import "../common.css";

@inject("content")
@observer
class CountTime extends Component {
  handleFormat = () => {
    const {content} = this.props.content;

    parseCountToTime(content, this.props.content);
  };

  render() {
    return (
      <div id="nice-menu-count-time" className="nice-menu-item" onClick={this.handleFormat}>
        <span>
          <span className="nice-menu-flag" />
          <span className="nice-menu-name">文字时间统计</span>
        </span>
        <span className="nice-menu-shortcut">{hotKeys.counttime}</span>
      </div>
    );
  }
}

export default CountTime;
