export default `/* 全局属性
 * 页边距 padding: 30px;
 * 全文字体 font-family: ptima-Regular;
 * 英文换行 word-break: break-all;
 */
#nice {
}

/* 段落，下方未标注标签参数均同此处
 * 上边距 margin-top: 5px;
 * 下边距 margin-bottom: 5px;
 * 行高 line-height: 26px;
 * 词间距 word-spacing: 3px;
 * 字间距 letter-spacing: 3px;
 * 对齐 text-align: left;
 * 颜色 color: #3e3e3e;
 * 字体大小 font-size: 16px;
 * 首行缩进 text-indent: 2em;
 */
#nice p {
}

/* 一级标题 */
#nice h1 {
}

/* 一级标题内容 */
#nice h1 .content {
}

/* 一级标题前缀 */
#nice h1 .prefix {
}

/* 一级标题后缀 */
#nice h1 .suffix {
}

/* 二级标题 */
#nice h2 {
}

/* 二级标题内容 */
#nice h2 .content {
}

/* 二级标题前缀 */
#nice h2 .prefix {
}

/* 二级标题后缀 */
#nice h2 .suffix {
}

#nice h2 .heading-number{
  display: none;
}

#nice h2 .heading-number-line{
  display: none;
}

/* 三级标题 */
#nice h3 {
}

/* 三级标题内容 */
#nice h3 .content {
}

/* 三级标题前缀 */
#nice h3 .prefix {
}

/* 三级标题后缀 */
#nice h3 .suffix {
}

/* 无序列表整体样式
 * list-style-type: square|circle|disc;
 */
#nice ul {
}

/* 有序列表整体样式
 * list-style-type: upper-roman|lower-greek|lower-alpha;
 */
#nice ol {
}

/* 列表内容，不要设置li
 */
#nice li section {
}

/* 一级引用
 * 左边缘颜色 border-left-color: black;
 * 背景色 background: gray;
 */
#nice .multiquote-1 {
}

/* 一级引用文字 */
#nice .multiquote-1 p {
}

/* 二级引用
 */
#nice .multiquote-2 {
}

/* 二级引用文字 */
#nice .multiquote-2 p {
}

/* 三级引用
 */
#nice .multiquote-3 {
}

/* 三级引用文字 */
#nice .multiquote-3 p {
}

/* 链接 
 * border-bottom: 1px solid #009688;
 */
#nice a {
}

/* 加粗 */
#nice strong {
}

/* 斜体 */
#nice em {
}

/* 加粗斜体 */
#nice em strong {
}

/* 删除线 */
#nice del {
}

/* 分隔线
* 粗细、样式和颜色
* border-top: 1px solid #3e3e3e;
*/
#nice hr {
}

/* 图片
* 宽度 width: 80%;
* 居中 margin: 0 auto;
* 居左 margin: 0 0;
*/
#nice img {
}

/* 图片描述文字 */
#nice figcaption {
}

/* 行内代码 */
#nice p code, #nice li code {
}

/* 
 * 代码块不换行 display: -webkit-box !important;
 * 代码块换行 display: block;
 */
#nice pre code {
}

/*
 * 表格内的单元格
 * 字体大小 font-size: 16px;
 * 边框 border: 1px solid #ccc;
 * 内边距 padding: 5px 10px;
 */
#nice table tr th,
#nice table tr td {
}

/* 
 * 某一列表格列宽控制
 * n 可以修改为具体数字，不修改时表示所有列
 * 最小列宽 min-width: 85px;
 */
#nice table tr th:nth-of-type(n),
#nice table tr td:nth-of-type(n){
}

/* 脚注文字 */
#nice .footnote-word {
}

/* 脚注上标 */
#nice .footnote-ref {
}

/* "参考资料"四个字 
 * 内容 content: "参考资料";
 */
#nice .footnotes-sep:before {
}

/* 参考资料编号 */
#nice .footnote-num {
}

/* 参考资料文字 */
#nice .footnote-item p { 
}

/* 参考资料解释 */
#nice .footnote-item p em {
}

/* 行间公式
 * 最大宽度 max-width: 300% !important;
 */
#nice .block-equation svg {
}

/* 行内公式
 */
#nice .inline-equation svg { 
}

/*
头部阅读时间
*/
#nice .time {
    font-family: PingfangSC-LIGHT, sans-serif;
    box-sizing: border-box;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

#nice .left_zong {
    display: inline-block;
    width: 150px;
    vertical-align: middle;
    padding: 8px 5px 5px 3px;
    border-right: 1px solid #372E72;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

#nice .left_zong_zp img {
    width: 6em;
    height: 6em;
    display: inline-block;
    vertical-align: middle;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    border-radius: 5px;
}

#nice .left_zong p {
  font-size: 14px;
  font-weight: bold;
  color: #372E72;
  line-height: 1.6;
  margin: 0px;
  text-align: center;
}

#nice .right_zong {
  display: inline-block;
  vertical-align: top;
  padding: 6px;
  width: 50%;
  margin: 10px 0px;
}

#nice .right_zong_bk {
  width: 120px;
  color: #372E72;
  border-radius: 1px;
  margin: auto;
  line-height: 20px;
  font-size: 14px;
  border-radius: 6px;
  border: 1px solid #372E72;
  padding: 8px 5px 8px 5px;
}

#nice .right_zong_bk section {
  font-size: 30px;
  color: #666;
  line-height: 32px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: bold;
}

#nice .right_zong_bk p {
  margin: 0px;
  text-align: center;
  padding-top: 0px;
  padding-bottom: 0px;
}

#nice .sudu {
  font-size: 11px;
  color: #aaa;
  padding-top: 3px;
  padding-bottom: 0px;
}

/**链接转二维码*/
#nice .qrcode{
    max-width: 800px;
}


#nice .qrcode-box{
    margin-top: 1rem; 
    border-radius: 0.5rem; 
    background-color: rgb(249, 250, 251); 
    color: rgb(55, 65, 81); 
    display: flex; 
    justify-content: space-between; 
    border: 1px solid rgb(239, 239, 239); 
    box-shadow: rgb(204, 204, 204) 2px 4px 7px; 
    margin-bottom: 20px;
    overflow: hidden;
}

#nice .qrcode-text span{
    width: 100%;
    word-break: break-all;
    padding-bottom: 6px;
}


`;
