function slugify(s, md) {
  // Unicode-friendly
  var spaceRegex = new RegExp(md.utils.lib.ucmicro.Z.source, "g");
  return encodeURIComponent(s.replace(spaceRegex, ""));
}

function h2_h3_counter(h2_3_list, tag) {
  const h2_3_dict = {h2: 0, h3: 0};
  const flag_list = new Array();
  const h2_index = h2_3_list.lastIndexOf("h2");
  h2_3_dict.h3 = h2_3_list.length - h2_index - 1;
  for (let i = 0; i <= h2_3_list.length; i++) {
    if (h2_3_list[i] === "h2") {
      h2_3_dict.h2 += 1;
    }
  }
  return h2_3_dict;
}

function makeRule(md, options) {
  return function addHeadingAnchors(state) {
    // Go to length-2 because we're going to be peeking ahead.
    let h2_count = 0;
    let h2_zong = 0;
    for (var j = 0; j < state.tokens.length - 1; j++) {
      if (state.tokens[j].type === "heading_open" && state.tokens[j].tag === "h2") {
        h2_zong += 1;
      }
    }
    const h2_3_list = [];

    for (var i = 0; i < state.tokens.length - 1; i++) {
      if (state.tokens[i].type !== "heading_open" || state.tokens[i + 1].type !== "inline") {
        continue;
      }

      var headingInlineToken = state.tokens[i + 1];

      if (!headingInlineToken.content) {
        continue;
      }

      if (options.addHeadingSpan) {
        var spanTokenPre = new state.Token("html_inline", "", 0);
        spanTokenPre.content = `<span class="prefix"></span><span class="content">`;
        headingInlineToken.children.unshift(spanTokenPre);
        var spanTokenPost = new state.Token("html_inline", "", 0);
        spanTokenPost.content = `</span><span class="suffix"></span>`;
        headingInlineToken.children.push(spanTokenPost);
      }

      if (options.addHeadingNum) {
        var spanTokenPre = new state.Token("html_inline", "", 0);
        spanTokenPre.content = ``;
        h2_3_list.push(state.tokens[i].tag);
        if (state.tokens[i].tag === "h2") {
          h2_count += 1;
          spanTokenPre.content =
            `</span><span class="heading-number">` +
            h2_count +
            `</span><span class="heading-number-line" style="line-height: 9px;color: white;border-radius: 10px;background: linear-gradient(to right,rgb(41, 148, 128) ` +
            (h2_count / h2_zong) * 100 +
            `%,rgb(73, 200, 149) 10%);">&nbsp;&nbsp;</span>`;
        }
        if (state.tokens[i].tag === "h3") {
          const a = h2_h3_counter(h2_3_list, "h3");
          // console.log(a);
          spanTokenPre.content = `<span class="heading-number">` + a.h2 + `.` + a.h3 + ` </span>`;
        }
        headingInlineToken.children.unshift(spanTokenPre);
        var spanTokenPost = new state.Token("html_inline", "", 0);
        spanTokenPost.content = ``;
        headingInlineToken.children.push(spanTokenPost);
      }

      // Advance past the inline and heading_close tokens.

      i += 2;
    }
  };
}

export default (md, opts) => {
  var defaults = {
    anchorClass: "markdown-it-headingspan",
    addHeadingSpan: true,
    addHeadingNum: true,
    slugify: slugify,
  };
  var options = md.utils.assign(defaults, opts);
  md.core.ruler.push("heading_span", makeRule(md, options));
};
