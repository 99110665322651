import {message} from "antd";
import prettier from "prettier/standalone";
import prettierMarkdown from "prettier/parser-markdown";
import {wordCalc} from "./helper";

const wrapChar = /windows|win32/i.test(navigator.userAgent) ? "\r\n" : "\n";

const handleWechatOuterLink = (content) => {
  const linkImgReg = /(!)*\[.*?\]\(((?!mp.weixin.qq.com).)*?\)/g;
  const res = content.match(linkImgReg); // 匹配到图片、链接和脚注

  if (res === null) {
    return content;
  }

  const footReg = /.*?\(.*?"(.*?)".*?\)/;
  const filterRes = res.filter((val) => {
    const comment = val.match(footReg);
    if (val[0] === "!") {
      return false;
    }
    if (comment && comment[1] !== "") {
      return false;
    }
    return true;
  }); // 过滤掉图片和脚注

  if (filterRes.length > 0) {
    filterRes.forEach((val) => {
      const linkReg = /\[(.*?)\]\((.*?)\)/; // 匹配链接中具体的值
      const matchValue = val.match(linkReg);
      const name = matchValue[1];
      const url = matchValue[2].trim();

      const newVal = `[${name}](${url} "${name}")`;
      content = content.replace(val, newVal);
    });
    return content;
  } else {
    return content;
  }
};

const handleReferenceLink = (content) => {
  const refLinkReg = /[\r\n]+(\s{0,3}?(?:(?:\d+\.|-)\s{0,3}?)?\[(\w+)\]:\s*([^"]+)(?:\s+"(\w+)")?\s*[\r\n]+)+/gi;
  let res;
  let newContent = content;

  const lineHandler = (refLinkText) => {
    const lineMatch = refLinkText.match(/\s{0,3}?(?:(?:\d+\.|-)\s{0,3}?)?\[(\w+)\]:\s*([^\s]+)(?:\s+"(\w+)")?/i);
    if (!lineMatch) {
      return;
    }
    const [rawLine, ref, link, title] = lineMatch;
    if (/^https?:\/\/mp\.weixin\.qq\.com/i.test(link)) {
      return;
    }

    const rawReg = new RegExp("\\[([^\\[\\]\\(\\)]+)\\]\\[" + ref + "\\]", "ig");
    newContent = newContent.replace(rawReg, (_, name) => `[${name}](${link} "${title || name}")`).replace(rawLine, "");
  };

  // eslint-disable-next-line no-cond-assign
  while ((res = refLinkReg.exec(content)) !== null) {
    const lines = res[0].split(/[\r\n]+/).filter((v) => v.trim());
    lines.forEach(lineHandler);
  }
  return newContent;
};

export const parseLinkToFoot = (content, store) => {
  content = handleWechatOuterLink(content);
  content = handleReferenceLink(content);
  content = content.replace(/([\u4e00-\u9fa5])\$/g, "$1 $");
  content = content.replace(/\$([\u4e00-\u9fa5])/g, "$ $1");
  store.setContent(content);
  message.success("微信外链转脚注完成！");
};

export const parseLinkToQrCode = () => {
  const ad = document.getElementsByClassName("qrcode-link");
  for (let i = 0; i < ad.length; i++) {
    const ad_href = ad[i].href;
    const ad_qrcode_href = "https://api.qinor.cn/qrcode/?text=" + ad_href;
    const ad_text = ad[i].text;
    const code =
      "<section class='qrcode-text' style='width: 60%;display: flex; flex-direction: column; justify-content: space-around; word-break: break-all; padding: 6px; font-size: 0.875rem; line-height: 1.25rem;'><span style='line-height: inherit; margin-top: 0px;'>长按识别二维码查看原文</span><span>" +
      ad_href +
      "</span><span>标题：" +
      ad_text +
      "</span></section><section class='qrcode-img' style='width: 124px; height: 124px;flex-shrink: 0;'><img alt='" +
      ad_href +
      "' src='" +
      ad_qrcode_href +
      "' class='medium-zoom-image' style='margin: auto; box-shadow: none; max-width: 100%;height: auto;vertical-align: middle; border-radius: 6px; display: block; object-fit: contain;'></section>";
    var qb = document.createElement("section");
    qb.setAttribute("class", "qrcode-box");
    qb.innerHTML = code;
    ad[i].parentNode.appendChild(qb);
  }
  message.success("链接转二维码完成！");
};

const handlePrettierDoc = (content) => {
  const prettierRes = prettier.format(content, {
    parser: "markdown",
    plugins: [prettierMarkdown],
  });
  return prettierRes;
};

export const parseCountToTime = (content, store) => {
  const wordCount = wordCalc(content);
  var readTime = wordCount / 500;
  var suduTime = wordCount / 1300;
  var p1 = "";
  var readTime = Math.round(readTime);
  var suduTime = Math.round(suduTime);
  if (readTime > 1 || suduTime > 1) {
    p1 =
      "<p>读完需要</p><section>" + readTime + '</section><p>分钟</p><p class="sudu">速读仅需 ' + suduTime + " 分钟</p>";
  } else {
    p1 = "<p>读完需要<section>" + 1 + '</section>分钟</p><p class="sudu">速读仅需 ' + 1 + " 分钟</p>";
  }
  var context =
    `<section class="time">
  <section class="left_zong">
  <section class="left_zong_zp">
  <img alt="头像照片" src="https://mmbiz.qpic.cn/mmbiz_png/LdibDpM4Nm7b6eUXkQIkzX5wf85yVnPKOqORibia5WYJ8pTx7k6icksSFf6nmUCf1KvyAU2vLsz3jdOiaqY9JnFGAnw/0?wx_fmt=png"/>
  </section>
  <p>文武科技社</p>
  </section>
  <section class="right_zong"><section class="right_zong_bk">` +
    p1 +
    `</section>
  <section style="box-sizing: border-box;border-width: 1px;padding: 7px 5px;width: 118px;margin: auto;line-height: 22px;font-size: 14px;visibility: visible;">
  <img alt="长按扫码动图" style="margin-right: auto;margin-left: auto;display: initial;visibility: visible !important;width: 139px !important;" src="https://mmbiz.qpic.cn/mmbiz_gif/2iauJXcR5ibephDezd9Sqbz8Y1ksEeia6DiakwvOb3eYalL52ZDhC22lju5yPribq3q5zCN5YKzaiaMBGTyIytEicEokg/640?wx_fmt=gif&amp;wxfrom=5&amp;wx_lazy=1"></section>
  </section></section>`;
  content = handlePrettierDoc(content);
  content = content.replace(/(\[Time\])/gi, context);
  console.log(content);
  store.setContent(content);
};

export const formatDoc = (content, store) => {
  content = handlePrettierDoc(content);
  content = content.replace(/([\u4e00-\u9fa5])\$/g, "$1 $");
  content = content.replace(/\$([\u4e00-\u9fa5])/g, "$ $1");
  store.setContent(content);
  message.success("格式化文档完成！");
};

export const bold = (editor, selection) => {
  editor.replaceSelection(`**${selection}**`);
  const cursor = editor.getCursor();
  cursor.ch -= 2;
  editor.setCursor(cursor);
};

export const bolder = (editor, selection) => {
  editor.replaceSelection(`<section class="strong-plus">
      <section class="strong-plus-right"><br></section>
      <section class="strong-plus-center">
        <p>${selection}</p>
      </section>
      <section class="strong-plus-left"><br></section>
    </section>`);
  const cursor = editor.getCursor();
  cursor.ch -= 2;
  editor.setCursor(cursor);
};

export const del = (editor, selection) => {
  editor.replaceSelection(`~~${selection}~~`);
  const cursor = editor.getCursor();
  cursor.ch -= 2;
  editor.setCursor(cursor);
};

export const italic = (editor, selection) => {
  editor.replaceSelection(`*${selection}*`);
  const cursor = editor.getCursor();
  cursor.ch -= 1;
  editor.setCursor(cursor);
};

export const code = (editor, selection) => {
  editor.replaceSelection(`${wrapChar}\`\`\`${wrapChar}${selection}${wrapChar}\`\`\`${wrapChar}`);
  const cursor = editor.getCursor();
  cursor.line -= 2;
  editor.setCursor(cursor);
};

export const inlineCode = (editor, selection) => {
  editor.replaceSelection(`\`${selection}\``);
  const cursor = editor.getCursor();
  cursor.ch -= 1;
  editor.setCursor(cursor);
};

export const h1 = (editor, selection) => {
  editor.replaceSelection(`# ${selection}`);
};

export const h2 = (editor, selection) => {
  editor.replaceSelection(`## ${selection}`);
};

export const h3 = (editor, selection) => {
  editor.replaceSelection(`### ${selection}`);
};
