import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Modal, Input, Button } from "antd";
import axios from "axios";
import {GEMINI_API_KEY} from "../../utils/constant";
const { TextArea } = Input;

@inject("content")
@observer
class GeminiDialog extends Component {
  state = {
    aiResponse: "",
    loading: false,
    search: "",
    apiKey: window.localStorage.getItem(GEMINI_API_KEY)?window.localStorage.getItem(GEMINI_API_KEY):""
  };

  aiRun = async () => {
    try {
      
      const { markdownEditor } = this.props.content;
      const systemPrompt = "你是中文markdown助手，对于所有的问题都用中文markdown格式排版回答。";
      const prompt = `${this.state.search}`;
      // const API_KEY = process.env.GROQ_API_KEY;
      const API_KEY = `${this.state.apiKey}`;
      window.localStorage.setItem(GEMINI_API_KEY, API_KEY);
      if(API_KEY){
          if (prompt.length === 0) {
          alert("请输入内容");
        } else {
          this.setState({ loading: true });
          const { data } = await axios.post(
            // "https://api.groq.com/openai/v1/chat/completions",
            // {
            //   messages: [
            //     {
            //       role: "user",
            //       content: systemPrompt + prompt
            //     }],
            //   model: "mixtral-8x7b-32768"
            // },
            "https://calm-pothos-2edf47.netlify.app/v1beta/models/gemini-1.0-pro-latest:generateContent?key="+API_KEY,
            {contents:[{parts:[{text: systemPrompt + prompt}]}]},
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                // Authorization: "Bearer "+API_KEY,
              },
            },
          );
          // if (data.choices[0]) {
          if(data.candidates[0]){
            // const result = data.choices[0].message.content;
            const result = data.candidates[0].content.parts[0].text;
            const cursor = markdownEditor.getCursor();
            cursor.ch -= 2;
            markdownEditor.setCursor(cursor);
            // 上传后实时更新内容
            const content = markdownEditor.getValue();
            this.props.content.setContent(result + content.replace("/", ""));
            markdownEditor.focus();
            this.setState({loading: false});
            this.closeDialog();
          }
        }
      }else{
        alert("请输入Gemini API Key");
      }
      
    } catch (err) {
      // setErr(err.message);
    }
  };

  handleClick = () => {
    this.aiRun();
  };

  handleChangeApiKey = (e) =>{
    this.setState({apiKey: e.target.value})
  }

  handleChangeSearch = (e) => {
    // console.log(e.target.value);
    this.setState({ search: e.target.value });
  };

  closeDialog = () => {
    this.props.dialog.setGeminiOpen(false);
  };

  render() {
    const { loading, search, aiResponse } = this.state;

    return (
      <Modal
        className="nice-md-gemini"
        title="AI助写"
        centered
        width={1080}
        visible={this.props.dialog.isGeminiOpen}
        onCancel={this.closeDialog}
        footer={null}
      >
        <div>
          <div style={{ display: "flex", flexDirection: "column" }}>
          <Input placeholder="请输入Gemini API Key" onChange={this.handleChangeApiKey} value={this.state.apiKey}></Input>
            <TextArea rows={4}
              style={style.textareaStyle}
              placeholder="AI辅助写作（示例1：写一段手机简介，示例2：矩阵怎么写）"
              onChange={this.handleChangeSearch}
              required
            />
            <Button style={style.buttonStyle} onClick={this.handleClick} loading={loading}>
              AI助写
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

const style = {
  textareaStyle: {
    padding: "10px",
    margin: "10px"
  },
  buttonStyle: {
    margin: "10px"
  },
};

export default inject("dialog")(observer(GeminiDialog));
