export default `

/* 全局属性
* 页边距 padding: 30px;
* 全文字体 font-family: ptima-Regular;
* 英文换行 word-break: break-all;
*/

#nice {
  background-image: linear-gradient(90deg,rgba(50,0,0,.07) 3%,transparent 0),linear-gradient(1turn,rgba(50,0,0,.07) 3%,transparent 0);
  background-size: 20px 20px;
  background-position: 50%;
  font-family: PingfangSC-LIGHT,sans-serif;
}

/* 段落，下方未标注标签参数均同此处
* 上边距 margin-top: 5px;
* 下边距 margin-bottom: 5px;
* 行高 line-height: 26px;
* 词间距 word-spacing: 3px;
* 字间距 letter-spacing: 3px;
* 对齐 text-align: left;
* 颜色 color: #3e3e3e;
* 字体大小 font-size: 16px;
* 首行缩进 text-indent: 2em;
*/
#nice p {
  margin: 20px;
  max-width: 100%;
  min-height: 1em;
  white-space: pre-wrap;
  color: rgb(43, 43, 43);
  text-align: justify;
  line-height: 1.5;
  box-sizing: border-box !important;
  word-wrap: break-word !important;
  letter-spacing: 1px;
}

/* 用于表情包展示 */
#nice p img{
  width: 36px;
  vertical-align: middle;
  display: inline;
}

/* 链接二维码展示 */
#nice .qrcode-img img{
  width: 100%;
}

/* 一级标题 */
#nice h1 {
}

/* 一级标题内容 */
#nice h1 .content {
}

/* 一级标题前缀 */
#nice h1 .prefix {
}

/* 一级标题后缀 */
#nice h1 .suffix {
}

/* 二级标题 */
#nice h2 {
  padding-bottom: 0.3em;
  text-align: center;
}



/* 二级标题内容 */
#nice h2 .content {
}

/* 二级标题前缀 */
#nice h2 .prefix {
  display: block;
  color: rgb(41, 148, 128);
}

/* 二级标题后缀 */
#nice h2 .suffix {
  background: linear-gradient(to right,rgb(41, 148, 128) 50%,rgb(73, 200, 149) 10%);
}

/* 二级标题前缀 */
#nice h2 .heading-number{
  color: #42b983;
}

#nice h2 .heading-number-line{
  display: block;
}

/* 三级标题 */
#nice h3 {
  font-size: 1.38em;
  color: #fff;
  background-color: #42b983;
  padding: 4px 10px;
  width: fit-content;
  margin: 10px auto 10px;
  border-radius: 6px;
  box-shadow: 1px 1px 2px #42b983;
  text-align: center;
}

/* 三级标题内容 */
#nice h3 .content {
}

/* 三级标题前缀 */
#nice h3 .prefix {
display: initial;
}

/* 三级标题后缀 */
#nice h3 .suffix {
}

/* 无序列表整体样式
* list-style-type: square|circle|disc;
*/
#nice ul {
  color: rgb(41, 148, 128);
}

/* 有序列表整体样式
* list-style-type: upper-roman|lower-greek|lower-alpha;
*/
#nice ol {
  color: rgb(41, 148, 128);
}

#nice ol li::marker{

}

/* 列表内容，不要设置li
*/
#nice li section {
  max-width: 100%;
  line-height: 30px;
  color: rgb(84, 84, 84);
  box-sizing: border-box !important;
  word-wrap: break-word !important;
  font-size: 15px;
  font-style: normal;
  font-family: PingfangSC-LIGHT,sans-serif;
}

/* 一级引用
* 左边缘颜色 border-left-color: black;
* 背景色 background: gray;
*/
#nice .multiquote-1 {
  padding: 0 1em;
  color: #6a737d;
  border-left: 0.25em solid #eaecef;
  margin: 0 0 16px;
  background-color: rgb(66, 185, 131,0.1);
  border-left: 6px solid rgb(66, 185, 131);
  padding: 16px;
  border-right: 2px solid rgb(66, 185, 131);
  letter-spacing: 1px;
  font-size: 15px;
}

/* 一级引用文字 */
#nice .multiquote-1 p {
}
#nice .multiquote-1::before{
  content: '“';
  left: 16px;
  top: 0;
  color: rgb(66, 185, 131);
  font-size: 2em;
  font-family: Arial, serif;
  line-height: 1em;
  font-weight: 700;
}

/* 二级引用
*/
#nice .multiquote-2 {
}

/* 二级引用文字 */
#nice .multiquote-2 p {
}

/* 三级引用
*/
#nice .multiquote-3 {
}

/* 三级引用文字 */
#nice .multiquote-3 p {
  font-size: 18px;
}

/* 链接 
* border-bottom: 1px solid #009688;
*/
#nice a {
  color: #42b983;
  border-bottom: 1px solid #42b983;
}

/* 加粗 */
#nice strong {
  max-width: 100%;
  line-height: 28px;
  box-sizing: border-box !important;
  word-wrap: break-word !important;
  letter-spacing: 1px;
  font-size: 15px;
  font-family: PingfangSC-LIGHT,sans-serif;
  background: linear-gradient(to right,rgb(73, 200, 149),rgb(38, 198, 218));
  color: white;
  white-space: pre-wrap;
  border-width: 0.25em 0;
  display: inline;
  padding: 2px 4px 2px 4px;
  box-shadow: 1px 1px 2px #888888;
  border-radius: 4px;
}

/* 斜体 */
#nice em {
color: #34495e;
}

/* 加粗斜体 */
#nice strong em{
color: white;
}

/* 删除线 */
#nice del {
}

/* 分隔线
* 粗细、样式和颜色
* border-top: 1px solid #3e3e3e;
*/
#nice hr {
}

/* 图片
* 宽度 width: 80%;
* 居中 margin: 0 auto;
* 居左 margin: 0 0;
*/
#nice img {
}

/* 行内代码 */
#nice p code, #nice li code {
background-color: rgba(27, 31, 35, .05);
color: #e96900;
}

/* 
* 代码块不换行 display: -webkit-box !important;
* 代码块换行 display: block;
*/
#nice pre code {
}

/*
* 表格内的单元格
* 字体大小 font-size: 16px;
* 边框 border: 1px solid #ccc;
* 内边距 padding: 5px 10px;
*/
#nice table tr th,
#nice table tr td {
}

/* 
* 某一列表格列宽控制
* n 可以修改为具体数字，不修改时表示所有列
* 最小列宽 min-width: 85px;
*/
#nice table tr th:nth-of-type(n),
#nice table tr td:nth-of-type(n){
}

/* 脚注文字 */
#nice .footnote-word {
color: #42b983;
}

/* 脚注上标 */
#nice .footnote-ref {
color: #42b983;
}

/* "参考资料"四个字 
* 内容 content: "参考资料";
*/
#nice .footnotes-sep:before {
}

/* 参考资料编号 */
#nice .footnote-num {
}

/* 参考资料文字 */
#nice .footnote-item p { 
}

/* 参考资料解释 */
#nice .footnote-item p em {
}

/* 行间公式
* 最大宽度 max-width: 300% !important;
*/
#nice .block-equation svg {
}

/* 行内公式
*/
#nice .inline-equation svg {  
}

/* 文章结尾 */
#nice:after{
  content:'- END -';
  font-size:15px;
  display:block;
  text-align:center;
  margin-top:50px;
  color:#999;
  border-bottom:1px solid #eee;
}


/*
头部阅读时间
*/
#nice .time {
  font-family: PingfangSC-LIGHT, sans-serif;
  box-sizing: border-box;
  text-align: center;
  max-width: 100%!important;
  overflow: hidden;
  color: #222;
  font-size: 17px;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  position: relative;
  z-index: 0;
}

#nice .left_zong {
    display: inline-block;
    vertical-align: top;
    padding: 8px 5px 5px 3px;
    width: 49%;
    box-sizing: border-box;
    border-right: 1px solid #372E72;
    visibility: visible;
}

#nice .left_zong_zp img {
    width: 120px;
    height: 126px;
    display: inline-block;
    vertical-align: middle;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  margin-top: 8px;
  border-radius: 6px;
  border: 1px solid #372E72;
}

#nice .left_zong p {
  font-size: 14px;
  font-weight: bold;
  color: #372E72;
  line-height: 1.6;
  margin: 0px;
  text-align: center;
}

#nice .right_zong {
  display: inline-block;
  vertical-align: top;
  padding: 6px;
  width: 49%;
  margin: 10px 0px;
}

#nice .right_zong_bk {
  width: 120px;
  color: #372E72;
  border-radius: 1px;
  margin: auto;
  line-height: 20px;
  font-size: 14px;
  border-radius: 6px;
  border: 1px solid #372E72;
  padding: 8px 5px 8px 5px;
}

#nice .right_zong_bk section {
  font-size: 30px;
  color: #666;
  line-height: 32px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: bold;
}

#nice .right_zong_bk p {
  margin: 0px;
  text-align: center;
  padding-top: 0px;
  padding-bottom: 0px;
}

#nice .sudu {
  font-size: 11px;
  color: #aaa;
  padding-top: 3px;
  padding-bottom: 0px;
}
/*
链接转二维码
*/
#nice .qrcode{
  max-width: 800px;
}


#nice .qrcode-box{
  margin-top: 1rem; 
  border-radius: 0.5rem; 
  background-color: rgb(249, 250, 251); 
  color: rgb(55, 65, 81); 
  display: flex; 
  justify-content: space-between; 
  border: 1px solid rgb(239, 239, 239); 
  box-shadow: rgb(204, 204, 204) 2px 4px 7px; 
  margin-bottom: 20px;
  overflow: hidden;
  padding: .5rem;
}

#nice .qrcode-text span{
  width: 100%;
  word-break: break-all;
  padding-bottom: 6px;
}

/*
加粗plus版本
*/

#nice .strong-plus {
  display: flex;
  flex-direction: column;
}

#nice .strong-plus-right {
  box-sizing: border-box;
  width: 60px;
  height: 45px;
  margin-right: 10px;
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  vertical-align: bottom;
  border-radius: 100%;
  background-image: url("http://mmbiz.qpic.cn/mmbiz_png/xpE6LLZTjLjWXmtVn4ibm5XW7U4Sib2UM0ntcU7iaAeKOibpzaSJBW29FRozKicqj4Q7HVxQ2I5hJPkHHZEm1k5344g/640?wx_fmt=png");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  float: right;
  background-size: 70%;
}

#nice .strong-plus-center {
  padding-right: 10px;
  padding-left: 10px;
  text-align: center;
  box-sizing: border-box;
  font-size: 18px;
}

#nice .strong-plus-center p {
  box-sizing: border-box;
  margin-left: 35px;
  margin-right: 35px;
  line-height: 32px;
  color: rgb(43, 56, 53);
  text-align: left;
}

#nice .strong-plus-left {
  box-sizing: border-box;
  width: 60px;
  height: 45px;
  margin-left: 10px;
  display: inline-block;
  vertical-align: bottom;
  border-radius: 100%;
  background-image: url("http://mmbiz.qpic.cn/mmbiz_png/xpE6LLZTjLjWXmtVn4ibm5XW7U4Sib2UM020LtDElW4XobqViauJ0QJ2icXJn2TOE9eoGNtpibsEtgU2QaichN7YEWgg/640?wx_fmt=png");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  float: left;
  background-size: 70%;
}


`;
